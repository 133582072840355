<template>
  <div class="ScreeningsUpdateAmounts">
    <AmountsPageHeader />

    <div class="InnerPage">
      <NextStepLine showTopBorder />

      <div class="OptionsWrap">
        <ToggleButton
          groupName="only-show-missing-types"
          value="only-show-missing-types"
          :isSelected="typesWithAmountsToggle"
          :text="mixWB('HIDE_MATERIALS_WITH_AMOUNTS_TOGGLE_TEXT')"
          @check="onToggleShowTypes" />
      </div>

      <div class="ListWrap">
        <div
          v-for="item in categoriesWithTypes"
          :key="item.category.id">
          <!-- Area title -->
          <span
            class="AreaTitle"
            v-if="item.areaTitle">{{ item.areaTitle }}</span>

          <!-- Category section -->
          <div
            class="CategorySection"
            :key="item.category.id">
            <span class="CategoryTitle">{{ mixWB(item.categoryTitle) }}</span>
            <div>
              <AmountTypeItem
                v-for="type in item.typesMissingAmount"
                :key="type.id"
                :type="type"
                :hasAmount="false"
                @edit-amount="onEditAmountClick" />
            </div>
            <div v-show="item.typesWithAmount.length">
              <!-- Header -->
              <div
                class="DoneListHeader"
                :class="{
                  IsOpen: showDoneTypes[item.category.id],
                  ShowTopBorder: item.typesMissingAmount.length,
                }"
                @click="onToggleDoneTypesClick(item.category.id)">
                <div
                  class="CheckIconWrap"
                  v-if="!item.typesMissingAmount.length">
                  <CheckIcon />
                </div>
                <span v-if="!item.typesMissingAmount.length">
                  {{ mixWB('ALL_TYPES_HAS_AMOUNTS') }}</span>
                <span v-else-if="item.typesWithAmount.length === 1">
                  {{ mixWB('1_TYPE_HAS_AMOUNT') }}</span>
                <span v-else>
                  {{ mixWB('X_TYPES_HAS_AMOUNTS', [item.typesWithAmount.length]) }}</span>
                <div class="IconWrap">
                  <AngleRightIcon />
                </div>
              </div>
              <div
                class="DoneListBody"
                v-show="showDoneTypes[item.category.id]">
                <AmountTypeItem
                  v-for="type in item.typesWithAmount"
                  :key="type.id"
                  :type="type"
                  :hasAmount="true"
                  @edit-amount="onEditAmountClick" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Dialog -->
    <Dialog
      :isShowing="showAmountDialog"
      :useComponent="AmountsModal"
      :componentProps="{
        type: typeToEdit,
      }"
      @close="showAmountDialog = false" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AngleRightIcon from '@/assets/svg/angle-right.svg?inline'
import CheckIcon from '@/assets/svg/check.svg?inline'
import AmountsPageHeader from '@/components/ScreeningItems/Amounts/AmountsPageHeader.vue'
import AmountTypeItem from '@/components/ScreeningItems/Amounts/AmountTypeItem.vue'
import ToggleButton from '@/components/FormElements/ToggleButton.vue'
import AmountsModal from '@/components/ScreeningItems/Amounts/AmountsModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NextStepLine from '@/components/ScreeningItems/NextStepLine.vue'

export default {
  name: 'ScreeningsUpdateAmounts',
  data() {
    return {
      typesWithAmountsToggle: true,
      showAmountDialog: false,
      typeToEdit: undefined,
      AmountsModal,
      showDoneTypes: {},
    }
  },
  computed: {
    ...mapGetters([
      'areasAsObject',
      'categoriesAsArray',
      'screeningTypes',
      'currentScreeningSelectedFilterUnitID',
    ]),
    categoriesWithTypes() {
      const list = []

      this.categoriesAsArray.forEach((category) => {
        const categoryTypes = this.screeningTypes.filter((x) => x.categoryID === category.id)
        let types = []
        if (this.currentScreeningSelectedFilterUnitID) {
          types = categoryTypes.filter(
            (x) => x.unitIDs.includes(this.currentScreeningSelectedFilterUnitID),
          )
        }
        else {
          types = categoryTypes
        }

        if (!types.length) {
          return
        }

        const item = {
          category,
          typesMissingAmount: [],
          typesWithAmount: [],
          total: types.length,
          areaTitle: '',
          categoryTitle: '',
        }

        // Check to set area title
        const lastListItem = list[list.length - 1]
        if (!lastListItem || lastListItem?.category?.areaID !== category.areaID) {
          item.areaTitle = this.mixWB(this.areasAsObject[category.areaID].translation)
        }

        // Split types into has and has no amounts
        types.forEach((type) => {
          const amountType = type?.amounts?.[0]?.data?.type
          if (!amountType || amountType === 'skip') {
            item.typesMissingAmount.push(type)
            return
          }
          item.typesWithAmount.push(type)
        })

        // Set category title
        item.categoryTitle = `${
          this.mixWB(category.translation)
        } (${
          item.total
        })`

        list.push(item)
      })

      return list
    },
  },
  watch: {
    categoriesWithTypes() {
      this.updateShowDoneTypes()
    },
  },
  methods: {
    onToggleShowTypes() {
      this.typesWithAmountsToggle = !this.typesWithAmountsToggle

      Object.keys(this.showDoneTypes).forEach((categoryID) => {
        this.showDoneTypes[categoryID] = !this.typesWithAmountsToggle
      })
    },
    onEditAmountClick(type) {
      this.showAmountDialog = true
      this.typeToEdit = type
    },
    onToggleDoneTypesClick(categoryID) {
      this.showDoneTypes[categoryID] = !this.showDoneTypes[categoryID]
    },
    updateShowDoneTypes() {
      this.categoriesWithTypes.forEach((item) => {
        if (this.showDoneTypes.hasOwnProperty(item.category.id)) {
          return
        }

        Vue.set(this.showDoneTypes, item.category.id, !this.typesWithAmountsToggle)
      })
    },
  },
  components: {
    AngleRightIcon,
    CheckIcon,
    AmountsPageHeader,
    AmountTypeItem,
    ToggleButton,
    Dialog,
    NextStepLine,
  },
  created() {
    this.updateShowDoneTypes()
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateAmounts
    pageWrap()

  .InnerPage
    page-wrap-inner-1()

  .OptionsWrap
    background-color $color_grey_lightest
    padding 20px 15px
    margin-bottom 20px

  .AreaTitle
    margin-bottom 7.5px
    font-weight bold
    text-transform uppercase
    border-bottom 1px solid $color_grey_lighter

  .CategorySection
    margin-bottom 15px

  .CategoryTitle
    margin-bottom 5px
    font-size 0.875rem
    text-transform uppercase

  .DoneListHeader
    position relative
    display flex
    background-color $color_grey_lightest
    padding 5px
    padding-right 7.5px
    &.ShowTopBorder
      border-top 1px solid $color_grey_lighter
    .CheckIconWrap
      box(12px)
      margin-right 5px
      svg
        fill $color_primary
    span
      color $color_grey
      font-size 0.75em
    .IconWrap
      position absolute
      top -7.5px
      right -7.5px
      z-index 1
      box(40px)
      padding 15px
      flex-center-children()
      transform rotateZ(-90deg)
      cursor pointer
      margin-top -1px
      svg
        box(100%)
    &.IsOpen
      .IconWrap
        transform rotateZ(90deg)
        margin-top 0px

  .DoneListBody
    border-top 1px solid $color_grey_lighter
    padding 5px
    background-color $color_grey_lightest
</style>
